import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HousePage from './pages/HousePage.tsx';
import TechnoPage from './pages/TechnoPage.tsx';
import FunkPage from './pages/FunkPage.tsx';
import PageWrapper from './components/PageWrapper.tsx';

import './App.css';
import './common.css'

const App = () => {
  // const [currentTechnoTrackIndex, setCurrentTechnoTrackIndex] = useState(4);

  return (
    <Router>
      <PageWrapper  >
        <Routes>
          <Route path="/" Component={TechnoPage} />
          <Route path="/house" Component={HousePage} />
          <Route path="/techno" Component={TechnoPage} />
          <Route path="/funk" Component={FunkPage} />
        </Routes>
      </PageWrapper>
    </Router>
  );
};

export default App;
