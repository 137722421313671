import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import houseImage from '../images/Housev4-FishEye-NoTitle-500x500.jpg';
import technoImage from '../images/ECPodCrowdControlv2500x500.jpg';
import funkImage from '../images/funk400hi.jpg';
import './NavBarDE.css'

const NavBarDE = ({ currentTrackIndex, setCurrentTrackIndex, setPageSelected }) => {
  const navigate = useNavigate();

  const handleClick = (path, index) => {
    console.log("handleClick, index:" + index)
    setCurrentTrackIndex(index);
    navigate(path);
  };

  useEffect(() => {
    console.log("Current track index is now:", currentTrackIndex);
  }, [currentTrackIndex]);

  return (
    <div className="container shared-max-width" /*className="navBarContainer "*/>
      <div className="row justify-content-center">
          <div className="Pages col-4 text-center techno">
              <Link to="/techno" onClick={(e) => {
                e.preventDefault();
                handleClick("/techno", 5);
                setPageSelected('techno');
                console.log("techno, currentTrackIndex:" + currentTrackIndex);
              }
                }>
                  <img
                      src={technoImage}
                      alt="Techno festival goers in the crowd"
                      className="rounded-image"
                  />
                  <h3 className="menu-text">techno</h3>
              </Link>
          </div>
          <div className="Pages col-4 text-center house">
              <Link to="/house" onClick={(e) => {
                  e.preventDefault();
                  handleClick("/house", 3);
                  setPageSelected('house');
                  console.log("house, currentTrackIndex:" + currentTrackIndex);
                }}>
                  <img
                      src={houseImage}
                      alt="Fisheye view of people at a house festival"
                      className="rounded-image"
                  />
                  <h3 className="menu-text">house</h3>
              </Link>
          </div>
          <div className="Pages col-4 text-center funk">
            <Link to="/funk" onClick={(e) => {
                e.preventDefault();
                handleClick("/funk", 2);
                setPageSelected('funk');
                console.log("funk, currentTrackIndex:" + currentTrackIndex);
              }}>
                <img src={funkImage} alt="Kerriem Riggins drumming" className="rounded-image" />
                <h3 className="menu-text">funk</h3>
              </Link>
          </div>
      </div>
  </div>
  );
}

export default NavBarDE;

