import React, { useState } from 'react';
import './pageCommon.css'
import './FunkPage.css'
import { FactsType } from '../interfaces/FactsType';
// import PlayerContainer from './PlayerContainer.tsx';
import MixTable from '../components/MixTable.tsx';
import { Mixes } from "../data/Mixes.tsx"
import SearchBar from '../components/SearchBar.tsx';
import Social from '../components/Social.tsx';
import HousePodcastRow from '../components/HousePodcastRow.tsx';

const funkData = {
  name: "Funk",
  featuredImage: '../images/btnAppleMusic.png',
  date: "2020-05-06T23:46:37.121Z",
  img: "../images/funk400hi.jpg",
  imgAlt: "Funk drummer playing funk drums",
  path: "/funk",
  title: "funk",
  remixDownloads: false,
  djMixDownloads: false,
  techno: false,
  embeds:
    [
  {
        source: "https://www.youtube.com/embed/Hjqcag5LC7Q",
        name: "Hands of Time Remix",
        height: 180,
      },

      {
        source: "https://open.spotify.com/embed/playlist/7CLPfDOCjgWcwo9g1tGPQo",
        name: "Underground Funk PL on Spotify",
        height: 160,
      },

  {
        source: "https://www.youtube.com/embed/BoPq1IB24Co",
        name: "Kid For Today DJ edit",
        height: 180,
      },

  // {
  //       source: "https://www.youtube.com/embed/zYQY1GIIRoA",
  //       name: "name",
  //       height: 180,
  //     },

      {
        source: "https://www.youtube.com/embed/stoRHlAhETs",
        name: "Do it till you're satisfied remix",
        height: 180,
      },

      {
        source: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/723474691&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        name: "Underground Funk episode on SC",
        height: 160,
      },

      {
        source: "https://open.spotify.com/embed/track/0EmuEwPWgmhI5OXIQThH3t",
        name: "If She Does Remix",
        height: 90,
      },

      {
        source: "https://www.youtube.com/embed/videoseries?list=PLMniNzct9Kea6tS0LvYMAk_iw4IvrPH30",
        // source: "https://www.youtube.com/embed/videoseries?si=nN-8KEjy_FAINfqM&amp;list=PLMniNzct9Kea6tS0LvYMAk_iw4IvrPH30",
        name: "Youtube Funk Playlist",
        height: 220,
      },
    ]
}

const FunkPage = () => {
  const [filterText, setFilterText] = useState("")
  function handleFilterTextInput(filterText: string) {
    setFilterText(filterText)
  }

  return (
      <section className="funk-page">
        <article className="shared-max-width">
        </article>
        <article
          className="page shared-max-width"
          itemScope
          itemType="http://schema.org/Article"
        >
          <ul style={{listStyleType: 'none', padding: 0, margin: 0 }}>
            {funkData.embeds.map((fact: FactsType, i: number) => (
              <li  key={funkData.name +  i}>
                <iframe
                  src={fact.source}
                  width="100%"
                  height={fact.height}
                  title={fact.name}
                  name={fact.name}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
                ></iframe>
              </li>
            ))}
          </ul>
        </article>
        <article  className="shared-max-width">
          <h4 className="Download-card">Remixes / Edits</h4>
          <SearchBar
            filterText={filterText}
            onFilterTextInput={handleFilterTextInput}
          />
          <MixTable mixes={Mixes} filterText={filterText} />
        </article>
        <article>
          <div className="col-md">
            <Social />
          </div>
        </article>
        <article>
          <div className="col-md">
            <HousePodcastRow />
          </div>
        </article>

      </section>
    );
};

export default FunkPage;
