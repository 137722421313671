import React from "react";

import "./Social.css";
import { PodcastLinkType } from '../interfaces/PodcastInterfaces';
import technopodcasts from '../data/technopodcasts.json';

const TechnoPodcastRow = () => {
  return (
    <div className="Podcast">
        {technopodcasts.map((link:PodcastLinkType, i:number) => (
          <div className="linkItem"  key={i}>
            <a href={link.href} target="_blank" rel="noreferrer" >
            <img
              src={link.imgSrc}
              alt={link.imgText}
              height="35"
            />
            </a>
          </div>
        ))}
    </div>
  );
};
export default TechnoPodcastRow;
