import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause, FaStepForward, FaStepBackward } from 'react-icons/fa';
import { HiMiniBackward, HiMiniForward } from 'react-icons/hi2';
import './Player.css';
import { Track } from '../interfaces/Track';

interface PlayerProps {
  currentTrack: {
    number: number;
    title: string;
    url: string;
    artwork: string;
    duration: string;
    episodeType: string;
    length: string;
    description: string;
  };
  handleNext: () => void;
  handlePrev: () => void;
  setIsPlaying: (isPlaying: boolean) => void;
  isPlaying: boolean;
  currentTrackIndex: number;
  tracks: Track[];
  onSwipe: (arg0: string) => void;
  onTap: () => void;
  handleTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleJumpBack: () => void;
  togglePlay: () => void;
  handleJumpForward: () => void;
  currentTime: number;
  progress: number;
  throttledHandleProgressChange: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleDragEnd: () => void;
  duration: number;
}

const formatTime = (time: number) => {
  const pad = (num: number, size: number) => `000${num}`.slice(size * -1);
  const timeInSeconds = Math.floor(time);
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  // Include hours only if there are any
  const hoursDisplay = hours > 0 ? `${hours}:` : '';
  return `${hoursDisplay}${pad(minutes, 2)}:${pad(seconds, 2)}`;
};


const Player: React.FC<PlayerProps> = ({
  currentTrack,
  handleNext,
  handlePrev,
  setIsPlaying,
  isPlaying,
  currentTrackIndex,
  tracks,
  onSwipe,
  onTap,
  handleTouchStart,
  handleTouchEnd,
  handleJumpBack,
  togglePlay,
  handleJumpForward,
  currentTime,
  progress,
  throttledHandleProgressChange,
  handleDragEnd,
  duration
}) => {

  return (
    <div className="player">

      <img src={currentTrack.artwork} alt={currentTrack.title}className="track-artwork"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      />
      <h3 className="track-title">{currentTrack.title}</h3>
      <div className="player-controls">
        <button onClick={handlePrev}><FaStepBackward size="3.5vh" /></button>
        <button onClick={handleJumpBack}><HiMiniBackward size="3.5vh" /></button>
        <button onClick={togglePlay}>{isPlaying ? <FaPause size="3.5vh" /> : <FaPlay size="3.0vh" />}</button>
        <button onClick={handleJumpForward}><HiMiniForward size="3.5vh" /></button>
        <button onClick={handleNext}><FaStepForward size="3.5vh" /></button>
      </div>
      <div className="time-controls">
        <span className="elapsed-time">{formatTime(currentTime)}</span>
        <input
          type="range"
          value={progress}
          onChange={throttledHandleProgressChange} // Update seekTime on drag
          onMouseUp={handleDragEnd}
          onTouchEnd={handleDragEnd}
          className="progress-bar"
          style={{ '--progress': `${progress}%` }} // sets CSS variable for progress
        />
        <span className="remaining-time">{formatTime(duration - currentTime)}</span>
      </div>
    </div>
  );
};

export default Player;
