import React, { useEffect, useRef, useState } from 'react';
import Player from '../components/Player.tsx';
import Playlist from '../components/Playlist.tsx';
import './PlayerContainer.css'
import DetailsPanel from '../components/DetailsPanel.tsx';
import {throttle} from 'lodash'


const PlayerContainer = ({ currentTrackIndex, setCurrentTrackIndex, setIsPlaying, isPlaying, pageSelected, tracks, reorderTracks }) => {
  const [view, setView] = useState('player'); // 'player' or 'playlist'
  const [showDetails, setShowDetails] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const currentTrack = tracks[currentTrackIndex];
  // console.log("Player, currentTrack is being initialized, tracks[currentTrackIndex]:" + JSON.stringify(tracks[currentTrackIndex]))


  const handleTap = () => {
    // Toggle the showDetails state on tap
    setShowDetails(prev => !prev);
  };

  const handleSwipe = (direction) => {
    // Check the swipe direction (left or right)
    if (direction === 'left') {
      setShowDetails(true);
    } else if (direction === 'right') {
      setShowDetails(false);
    }
  };

  const handleNext = () => {
    const orderedTracks = reorderTracks(pageSelected);
    const currentIndex = orderedTracks.findIndex(track => track === currentTrack);
    setCurrentTrackIndex((currentIndex + 1) % orderedTracks.length);
  };

  const handlePrev = () => {
    const orderedTracks = reorderTracks(pageSelected);
    const currentIndex = orderedTracks.findIndex(track => track === currentTrack);
    const newIndex = (currentIndex - 1 + orderedTracks.length) % orderedTracks.length;
    setCurrentTrackIndex(newIndex);
  };

//   const handleSelectTrack = (index: number) => {
//     const orderedTracks = reorderTracks(pageSelected);
//     const actualIndex = tracks.indexOf(orderedTracks[index]);
//     setCurrentTrackIndex(actualIndex);
//     setView('player');

//     if (audioRef.current) {
//         // Pause the current audio (if playing) before changing the source
//         if (isPlaying) {
//             audioRef.current.pause();
//         }

//         // Change the audio source
//         audioRef.current.src = orderedTracks[index].url;
//         audioRef.current.load(); // Load the new source

//         // Attempt to play the new track
//         audioRef.current.play().then(() => {
//             setIsPlaying(true);
//         }).catch(error => {
//             console.error("Error playing audio:", error);
//             setIsPlaying(false);
//         });
//     }
// };

  const handleSelectTrack = (index: number) => {
    const orderedTracks = reorderTracks(pageSelected);
    const actualIndex = tracks.indexOf(orderedTracks[index]);
    setCurrentTrackIndex(actualIndex);
    setView('player');

    setIsPlaying(true); // Set isPlaying to true to start playback in useEffect
  };


  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    console.log("handleTouchStart")
    const touch = e.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!touchStart) return;
    const touchEnd = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
    const diffX = touchStart.x - touchEnd.x;
    const diffY = touchStart.y - touchEnd.y;
    console.log("handleTouchEnd, diffX" + diffX)

    // Determine if the swipe is horizontal and its direction
    if (Math.abs(diffX) > Math.abs(diffY)) { // Horizontal swipe
      if (diffX > 0) { // Swiped left
        // props.onSwipe('left');
        handleSwipe('left');
      } else { // Swiped right
        // props.onSwipe('right');
        handleSwipe('right');
      }
    } else { // Consider as a tap if not a horizontal swipe
      // props.onTap();
      handleTap();
    }
  };


  // useEffect(() => {
  //   console.log ("useEffect, currentTrack:" + JSON.stringify(currentTrack))
  //   audioRef.current = new Audio(currentTrack.url);
  //   const audio = audioRef.current;
  //   console.log ("Player, current track useEffect called")
  //   const setAudioData = () => {
  //     console.log("Setting up audio data, audio.duration" + audio.duration)
  //     setDuration(audio.duration);
  //   };

  //   const handleTimeUpdate = () => {
  //     setCurrentTime(audio.currentTime);
  //   };

  //   audio.addEventListener('loadedmetadata', setAudioData);
  //   audio.addEventListener('timeupdate', handleTimeUpdate);

  //   return () => {
  //     audio.removeEventListener('loadedmetadata', setAudioData);
  //     audio.removeEventListener('timeupdate', handleTimeUpdate);
  //   };
  // }, [currentTrack.url, currentTrackIndex, currentTrack]);

//   useEffect(() => {
//     console.log("useEffect, currentTrack:", JSON.stringify(currentTrack));

//     // Stop and clean up the previous audio if it exists
//     if (audioRef.current) {
//       audioRef.current.pause();
//       audioRef.current.src = "";
//       audioRef.current.load(); // Load with no source to reset the audio element
//     }

//     const audio = new Audio(currentTrack.url);
//     audioRef.current = audio;

//     const setAudioData = () => {
//         console.log("Setting up audio data, audio.duration:", audio.duration);
//         setDuration(audio.duration);
//     };

//     const handleTimeUpdate = () => {
//         setCurrentTime(audio.currentTime);
//     };

//     audio.addEventListener('loadedmetadata', setAudioData);
//     audio.addEventListener('timeupdate', handleTimeUpdate);

//     // Play the audio if it should be playing
//     // if (isPlaying) {
//     //   audio.play().catch((error) => console.error("Error playing audio:", error));
//     // }

//     return () => {
//         audio.removeEventListener('loadedmetadata', setAudioData);
//         audio.removeEventListener('timeupdate', handleTimeUpdate);
//     };
// }, [currentTrack/*, isPlaying*/]);

    // useEffect(() => {
    //   if (audioRef.current) {
    //       audioRef.current.pause();
    //       audioRef.current.src = currentTrack.url;
    //       audioRef.current.load();

    //       const setAudioData = () => {
    //           setDuration(audioRef.current.duration);
    //       };

    //       const handleTimeUpdate = () => {
    //           setCurrentTime(audioRef.current.currentTime);
    //       };

    //       audioRef.current.addEventListener('loadedmetadata', setAudioData);
    //       audioRef.current.addEventListener('timeupdate', handleTimeUpdate);

    //       return () => {
    //           audioRef.current.removeEventListener('loadedmetadata', setAudioData);
    //           audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    //       };
    //   }
    // }, [currentTrack]);

    useEffect(() => {
      if (!audioRef.current) {
          audioRef.current = new Audio();
      }

      const audio = audioRef.current;
      audio.src = currentTrack.url;
      audio.load();

      const setAudioData = () => {
          setDuration(audio.duration);
      };

      const handleTimeUpdate = () => {
          setCurrentTime(audio.currentTime);
      };

      audio.addEventListener('loadedmetadata', setAudioData);
      audio.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
          audio.removeEventListener('loadedmetadata', setAudioData);
          audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
  }, [currentTrack]);

    useEffect(() => {
      if (audioRef.current && isPlaying) {
          audioRef.current.play().catch(error => console.error("Error playing audio:", error));
      }
    }, [isPlaying]);

  // useEffect(() => {
  //   console.log("Player, useEffect, currentTime:" + currentTime + ", duration:" +  duration)
  //   setProgress((currentTime / duration) * 100);
  // }, [currentTime, duration]);

  const togglePlay = async () => {
    console.log("togglePlay called, isPlaying:" + isPlaying)
    if (audioRef.current) {
      try {
        console.log("togglePlayer, audioRef.current check passed")
        if (isPlaying) {
          console.log("togglePlay: about to pause:")
          audioRef.current.pause();
        } else {
          console.log("togglePlay: about to play:")
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
      catch (error) {
        console.error("Error in playing audio:", error);
      }
    }
  };

  // Updating seekTime as the slider moves
  const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = (duration * Number(event.target.value)) / 100;
    setProgress(Number(event.target.value));
    setSeekTime(newTime); // Temporarily store the new time
  };

  // Throttle the function so it only executes at most once every 100 milliseconds
  // const throttledHandleProgressChange = throttle(handleProgressChange, 100);

  const throttledHandleProgressChange: (event: React.ChangeEvent<HTMLInputElement>) => void = throttle(handleProgressChange, 100);

  // Apply seekTime when user stops dragging slider
  const handleDragEnd = () => {
    console.log("handleProgressChange, before if:")
    if (audioRef.current && seekTime !== null) {
      audioRef.current.currentTime = seekTime;
      setSeekTime(null); // Reset the seekTime
      console.log("handleProgressChange, in if:")
    }
  };

  const handleJumpForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(audioRef.current.currentTime + 30, audioRef.current.duration);
    }
  };

  const handleJumpBack = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(audioRef.current.currentTime - 15, 0);
    }
  };

  return (
      <div className="player-container shared-max-width">
        {
          showDetails ? (
            <DetailsPanel details={currentTrack.description} onClose={() => setShowDetails(false)} />
          ) : (
            // Show either the Player or Playlist
            view === 'player' ? (
              <Player
                currentTrack={currentTrack}
                handleNext={handleNext}
                handlePrev={handlePrev}
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                currentTrackIndex={currentTrackIndex}
                tracks={tracks}
                onSwipe={handleSwipe}
                onTap={handleTap}
                handleTouchStart = {handleTouchStart}
                handleTouchEnd = {handleTouchEnd}
                handleJumpBack = {handleJumpBack}
                togglePlay = {togglePlay}
                handleJumpForward = {handleJumpForward}
                currentTime = {currentTime}
                progress = {progress}
                throttledHandleProgressChange = {throttledHandleProgressChange}
                handleDragEnd = {handleDragEnd}
                duration = {duration}
                />
              ) : (
              <Playlist tracks={tracks} onSelectTrack={handleSelectTrack} pageSelected={pageSelected} />
              )
            )
          }
        <button
          onClick={() => setView(view === 'player' ? 'playlist' : 'player')}
          className="switch-button">
          {view === 'player' ? 'Show Playlist' : 'Show Player'}
        </button>
      </div>
    );
  };

  export default PlayerContainer;
