import React, {useEffect, useState} from 'react';
import { useLocation} from 'react-router-dom';
import PlayerContainer from '../pages/PlayerContainer.tsx';
import NavBarDE from './NavBarDE.tsx';
import tracks from '../data/tracks.json';

const PageWrapper = ({
  children,
}) => {
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [pageSelected, setPageSelected] = useState('techno');
  const [reorderedTracks, setReorderedTracks] = useState(tracks);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(1);

  const reorderTracks = (selectedPage) => {
    const orderedTracks = [...tracks].sort((a, b) => {
        if (a.episodeType === selectedPage) return -1;
        if (b.episodeType === selectedPage) return 1;
        return 0;
    });
    return orderedTracks;
  };

  useEffect(() => {
    console.log("Setting pageClass (and possibly pageSelected), location.pathname:" + location.pathname)
    if (location.pathname.includes('/funk')) {
      setPageSelected('funk');
    } else if (location.pathname.includes('/house')) {
      setPageSelected('house');
    } else if (location.pathname.includes('/techno')) {
      setPageSelected('techno');
    } else if (location.pathname.includes('/')) {  // the main page / default route
      setPageSelected('techno');
    }
  }, [location.pathname])

  useEffect(() => {
    const newOrderedTracks = reorderTracks(pageSelected);
    console.log("PageWrapper useEffect, Setting reorderedTracks")
    setReorderedTracks(newOrderedTracks);
  }, [pageSelected]);

  return (
    <section  className={`${pageSelected}-page`} >
      <NavBarDE currentTrackIndex={currentTrackIndex} setCurrentTrackIndex={setCurrentTrackIndex}  setPageSelected={setPageSelected}  />
      <PlayerContainer currentTrackIndex={currentTrackIndex} setCurrentTrackIndex={setCurrentTrackIndex} setIsPlaying={setIsPlaying} isPlaying={isPlaying} pageSelected={pageSelected} tracks={reorderedTracks} reorderTracks={reorderTracks} />

      {children}
    </section>
  );
};

export default PageWrapper;