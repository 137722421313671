import React, { useState } from 'react';
import './pageCommon.css'
import './HousePage.css'
import { FactsType } from '../interfaces/FactsType';
import PlayerContainer from './PlayerContainer.tsx';
import SearchBar from '../components/SearchBar.tsx';
import MixTable from '../components/MixTable.tsx';
import { djMixes } from "../data/djMixes.tsx"
import Social from '../components/Social.tsx';
import HousePodcastRow from '../components/HousePodcastRow.tsx';
import housepodcasts from '../data/housepodcasts.json';
import { PodcastLinkType } from '../interfaces/PodcastInterfaces.ts';
import TechnoPodcastRow from '../components/TechnoPodcastRow.tsx';

const houseData = {
  name: "House",
  featuredImage: '../images/btnAppleMusic.png',
  date: "2023-12-04T23:46:37.121Z",
  img: "../images/Housev4-FishEye-NoTitle-500x500.jpg",
  imgAlt: "Fisheye view of people at a house festival",
  path: "/house",
  title: "house",
  remixDownloads: false,
  djMixDownloads: false,
  techno: false,
  embeds:
    [
      {
        source: "https://open.spotify.com/embed/track/53tFjPPORlc9VjWNLRdXAq?utm_source=generator",
        name: "Cant Die",
        height: 85,
      },

      {
        source: "https://open.spotify.com/embed/album/2ZZ8XskExzRJ3T7e6pNJUE?utm_source=generator",
        name: "name",
        height: 85,
      },

      {
        source: "https://www.youtube.com/embed/Hjqcag5LC7Q",
        name: "name",
        height: 180,
      },

      {
        source: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/923486665&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        name: "UltrafieldsMissy",
        height: 220,
      },

      {
        source: "https://www.youtube.com/embed/yYHYUDogBJw",
        name: "name",
        height: 220,
      },

      {
        source: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/668714891&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        name: "name",
        height: 160,
      },

      {
        source: "https://open.spotify.com/embed/track/1WXjNKjwp4iRKK0egpv7Rt",
        name: "name",
        height: 85,
      },

      {
        source: "https://open.spotify.com/embed/track/3rihedoymkkfQpa1J6vE0Q",
        name: "name",
        height: 85,
      },
    ]
}

const HousePage = () => {
  const [filterDjMixText, setFilterDjMixText] = useState("")

  function handleDJFilterTextInput(filterText: string) {
    setFilterDjMixText(filterText)
  }

  return (
    <section className="house-page">
    <article
    // className="page shared-max-width"
    className="shared-max-width"
    itemScope
    itemType="http://schema.org/Article"
  >
    <ul style={{listStyleType: 'none', padding: 0, margin: 0 }}>
      {houseData.embeds.map((fact: FactsType, i: number) => (
        <li  key={houseData.name +  i}>
          <iframe
            src={fact.source}
            width="100%"
            height={fact.height}
            title={fact.name}
            name={fact.name}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </li>
      ))}
    </ul>
  </article>

  <article>
    <div className="col-md">
      <Social />
    </div>
  </article>
  <article>
    <div className="col-md">
      <HousePodcastRow />
    </div>
  </article>
</section>
  );
};

export default HousePage;
