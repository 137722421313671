import React from 'react';
import  './Playlist.css'
import { Track } from '../interfaces/Track';

interface PlaylistProps {
  tracks: Track[];
  onSelectTrack: (index: number) => void;
  pageSelected: string;
}

// ${pageSelected}-page   ${track.episodeType}-page

const Playlist: React.FC<PlaylistProps> = ({ tracks, onSelectTrack, pageSelected }) => {
  return (
    <div className="playlist">
      {tracks.map((track, index) => (
        <div key={index} className={`playlist-item ${track.episodeType}-page`} onClick={() => onSelectTrack(index)}>
          <div>
            <img src={track.artwork} alt={track.title} className="playlist-artwork" />
          </div>
          <div className="playlist-info">
            <h4 className="playlist-title">{track.title}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Playlist;
