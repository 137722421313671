import React from 'react';
import './DetailsPanel.css';


interface DetailsPanelProps {
  details: string;
  onClose: () => void;
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({ details, onClose }) => {
  return (
    <div className="details-panel">
      <p>{details}</p>
      <button onClick={onClose} className="close-details-button">
        Close Details
      </button>
    </div>
  );
};

export default DetailsPanel;
