import React from "react";

import "./Social.css";
import { PodcastLinkType } from '../interfaces/PodcastInterfaces';
import housepodcasts from '../data/housepodcasts.json';


const HousePodcastRow = () => {
  return (
    <div className="Podcast">
      {housepodcasts.map((link:PodcastLinkType, i:number) => (
        <div className="linkItem" key={i}>
          <a href={link.href} target="_blank" rel="noreferrer" >
          <img
            src={link.imgSrc}
            alt={link.imgText}
            height="35"
          />
          </a>
        </div>
      ))}
    </div>
  );
};

export default HousePodcastRow;

