import React from 'react';
import './pageCommon.css'
import './TechnoPage.css'
import { FactsType } from '../interfaces/FactsType';
import Social from '../components/Social.tsx';
import TechnoPodcastRow from '../components/TechnoPodcastRow.tsx';
// import PlayerContainer from './PlayerContainer.tsx';

const technoData = {
  name: "Techno",
  featuredImage: '../images/btnAppleMusic.png',
  date: "2023-12-04T23:46:37.121Z",
  img: "../images/ECPodCrowdControlv2500x500.jpg",
  imgAlt: "Techno ravers at a festival",
  path: "/techno",
  title: "techno",
  src: null,
  remixDownloads: false,
  djMixDownloads: false,
  techno: true,
  embeds:
    [
      {
        source: "https://www.youtube.com/embed/HP3jBYvTvDQ",
        name: "name",
        height: 300,
      },

      {
        source: "https://open.spotify.com/embed/playlist/6iqxF99IunM1sOPdqvQ3Q7?utm_source=generator",
        name: "name",
        height: 160,
      },

      {
        source: "https://www.youtube.com/embed/R7ezWNc6zaY",
        name: "name",
        height: 300,
      },

      {
        source: "https://www.youtube.com/embed/edqev5Ax0dc",
        name: "name",
        height: 300,
      },

      {
        source: "https://www.youtube.com/embed/videoseries?list=PLMniNzct9KeZsJpQoF_pdXwB0NQsF94Sd",
        name: "name",
        height: 220,
      },

      {
        source: "https://www.youtube.com/embed/lrjru5USh7Y",
        name: "name",
        height: 300,
      },
  ]
}

const TechnoPage = () => {
    return (

      <section className="techno-page">

        <article
          className="page shared-max-width"
          itemScope
          itemType="http://schema.org/Article"
        >
          <ul style={{listStyleType: 'none', padding: 0, margin: 0 }}>
            {technoData.embeds.map((fact: FactsType, i: number) => (
              <li  key={technoData.name +  i}>
                <iframe
                  src={fact.source}
                  width="100%"
                  height={fact.height}
                  title={fact.name}
                  name={fact.name}
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                ></iframe>
              </li>
            ))}
          </ul>
        </article>

        <article>
          <div className="col-md">
            <Social />
          </div>
        </article>
        <article>
          <div className="col-md">
            <TechnoPodcastRow />
          </div>
        </article>
      </section>
    );
};

export default TechnoPage;
